import { FunctionComponent, useEffect, useState } from 'react';
import { ConfigContentPropsInterface } from '../ConfigContentPropsInterface';
import styles from '../IntercomConfigMenuContent.module.scss';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import DefaultViewControl from '../../../../../components/common/defaultViewControl/DefaultViewControl';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const TimeZoneList = [
  { text: '(GMT-12:00) Eniwetok, Kwajalein', value: '-720a'},
  { text: '(GMT-11:00) Midway Island, Samoa', value: '-660a'},
  { text: '(GMT-10:00) Hawaii', value: '-600a'},
  { text: '(GMT-09:00) Alaska', value: '-540a'},
  { text: '(GMT-08:00) Pacific Time (US & Canada)', value: '-480a'},
  { text: '(GMT-07:00) Arizona', value: '-420b'},
  { text: '(GMT-07:00) Mountain Time (US & Canada)', value: '-420a'},
  { text: '(GMT-06:00) Central Time (US & Canada)', value: '-360b'},
  { text: '(GMT-06:00) Mexico City, Tegucigalpa', value: '-360a'},
  { text: '(GMT-05:00) Bogota, Lima, Quito', value: '-300c'},
  { text: '(GMT-05:00) Eastern Time (US & Canada)', value: '-300b'},
  { text: '(GMT-05:00) Indiana (East)', value: '-300a'},
  { text: '(GMT-04:00) Atlantic Time (US & Canada)', value: '-240b'},
  { text: '(GMT-04:00) Caracas, La Paz', value: '-240a'},
  { text: '(GMT-03:30) Newfoundland', value: '-210a'},
  { text: '(GMT-03:00) Brasilia', value: '-180b'},
  { text: '(GMT-03:00) Buenos Aires, Georgetown', value: '-180a'},
  { text: '(GMT-02:00) Mid-Atlantic', value: '-120a'},
  { text: '(GMT-01:00) Azores, Cape Verde Is.', value: '-60a'},
  { text: '(GMT) Casablanca, Monrovia', value: '0'},
  { text: '(GMT) Dublin, Edinburgh, Lisbon, London', value: '0a'},
  { text: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', value: '60a'},
  { text: '(GMT+01:00) Belgrade, Bratislave, Budapest, Ljubljana, Prague', value: '60b'},
  { text: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris, Vilnius', value: '60c'},
  { text: '(GMT+01:00) Sarajevo, Skopje, Sofija, Warsaw, Zagreb', value: '60d'},
  { text: '(GMT+02:00) Athens, Bucharest, Cairo, Istanbul, Minsk', value: '120a'},
  { text: '(GMT+02:00) Harare, Helsinki, Jerusalem, Pretoria, Riga, Tallinn', value: '120b'},
  { text: '(GMT+03:00) Baghdad, Kuwait, Riyadh', value: '180a'},
  { text: '(GMT+03:00) Moscow, St.Petersburg, Volgograd', value: '180b'},
  { text: '(GMT+03:30) Tehran', value: '210a'},
  { text: '(GMT+04:00) Abu Dhabi, Baku, Muscat, Tbilist', value: '240a'},
  { text: '(GMT+04:30) Kabul', value: '270a'},
  { text: '(GMT+05:00) EKaterinburg, Islamabad, Karachi, Tashikent', value: '300a'},
  { text: '(GMT+05:30) New Delhi', value: '330a'},
  { text: '(GMT+06:00) Astana, Almaty, Colombo, Dhaka', value: '360a'},
  { text: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: '420a'},
  { text: '(GMT+08:00) Beijing, Hong Kong, Singapore, Taipei', value: '480a'},
  { text: '(GMT+09:00) Seoul, Tokoyo, Yakutsk', value: '540a'},
  { text: '(GMT+09:30) Adelaide, Darwin', value: '570a'},
  { text: '(GMT+10:00) Canberra, Guam, Port Moresby, Vladivostok', value: '600a'},
  { text: '(GMT+11:00) Magadan, Solomon Islands', value: '660a'},
  { text: '(GMT+12:00) Fiji, Kamchatka, Marshall Islands, Willington', value: '720a'},
]

const IntercomConfigSystem: FunctionComponent<ConfigContentPropsInterface> = (props: ConfigContentPropsInterface) => {
  const [expanded, setExpanded] = useState({
    firstPanel: true,
    secondPanel: true,
    thirdPanel: true,
  });
  const [administratorUsername, setAdministratorUsername] = useState<string>();
  const [administratorPassword, setAdministratorPassword] = useState<string>();
  const [syslogEnabled, setSyslogEnabled] = useState<boolean>();
  const [syslogServer, setSyslogServer] = useState<string>();
  const [syslogPort, setSyslogPort] = useState<number>();
  const [ntpEnabled, setNtpEnabled] = useState<boolean>();
  const [ntpServer, setNtpServer] = useState<string>();
  const [ntpDst, setNtpDst] = useState<boolean>();
  const [ntpTimezone, setNtpTimezone] = useState<any>();

  useEffect(() => {
    setValues(props.model);
  }, []);
  if (!props.model) {
    return (
      <></>
    );
  }

  const setValues = (model: any) => {
    if (model && model.administrator && model.syslog && model.ntp) {
      const administrator = model.administrator;
      const syslog = model.syslog;
      const ntp = model.ntp;
      setAdministratorUsername(administrator.username);
      setAdministratorPassword(administrator.password);
      setSyslogEnabled(syslog.enabled);
      setSyslogServer(syslog.server);
      setSyslogPort(syslog.port);
      setNtpEnabled(ntp.enabled);
      setNtpServer(ntp.server);
      setNtpDst(ntp.dst);
      setNtpTimezone({ text: TimeZoneList.find((i) => i.value === ntp.time_zone)?.text, value: ntp.time_zone });
    }
  }

  const save = () => {
    let updateModel = {
      intercom:
        {
          system: {
            administrator: {
              username: administratorUsername,
              password: administratorPassword,
            },
            syslog: {
              enabled: syslogEnabled,
              server: syslogServer,
              port: syslogPort,
            },
            ntp: {
              enabled: ntpEnabled,
              dst: ntpDst,
              server: ntpServer,
              time_zone: ntpTimezone?.value,
            },
          },
        },
    };
    let updateConfigModel = {Config: updateModel, IsPartial: true}
    props.saveEvent(updateConfigModel);
  };

  const cancel = () => {
    setValues(props.model);
    props.cancelEvent();
  };
  return (
    <>
      <div className={styles.pageWrapperIntercomConfigContent} id={'IntercomConfigContentExpander'}>
        <div className={styles.panelRowSpaceBetween} style={{ margin: 10 }}>
          <div className={styles.pageTitle}>System</div>
        </div>
        <ExpansionPanel
          title={'Authentication'}
          expanded={expanded.firstPanel}
          tabIndex={0}
          onAction={(event: ExpansionPanelActionEvent) => {
            setExpanded({ ...expanded, firstPanel: !expanded.firstPanel });
          }}
        >
          <div>
            {expanded.firstPanel}
            {expanded.firstPanel && (
              <ExpansionPanelContent>
                {props.editMode ?
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Username</div>
                        <div className='k-form-field-wrap'>
                          <Input type='text' value={administratorUsername}
                                 onChange={(ev) => {
                                   setAdministratorUsername(ev.value);
                                 }} />
                        </div>
                      </div>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Password</div>
                        <div className='k-form-field-wrap'>
                          <Input type='password' value={administratorPassword}
                                 onChange={(ev) => {
                                   setAdministratorPassword(ev.value);
                                 }} />
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Username'} value={administratorUsername}></DefaultViewControl>
                      </div>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl passwordView={true} label={'Password'} value={administratorPassword}></DefaultViewControl>
                      </div>
                    </div>
                  </>
                }
              </ExpansionPanelContent>
            )}
          </div>
        </ExpansionPanel>
        <ExpansionPanel
          title={'Syslog'}
          expanded={expanded.secondPanel}
          tabIndex={1}
          onAction={(event: ExpansionPanelActionEvent) => {
            setExpanded({ ...expanded, secondPanel: !expanded.secondPanel });
          }}
        >
          <div>
            {expanded.secondPanel}
            {expanded.secondPanel && (
              <ExpansionPanelContent>
                {props.editMode ?
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Report to Server</div>
                        <Checkbox label={'Enable'} value={syslogEnabled} onChange={(ev) => setSyslogEnabled(ev.value)}></Checkbox>
                      </div>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Server</div>
                        <div className='k-form-field-wrap'>
                          <Input type='text' value={syslogServer}
                                 onChange={(ev) => {
                                   setSyslogServer(ev.value);
                                 }} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Port</div>
                        <div className='k-form-field-wrap'>
                          <Input type='number' value={syslogPort}
                                 onChange={(ev) => {
                                   setSyslogPort(Number(ev.value));
                                 }} />
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Report to Server'} value={syslogEnabled}></DefaultViewControl>
                      </div>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Server'} value={syslogServer}></DefaultViewControl>
                      </div>
                    </div>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Port'} value={syslogPort}></DefaultViewControl>
                      </div>
                    </div>
                  </>
                }
              </ExpansionPanelContent>
            )}
          </div>
        </ExpansionPanel>
        <ExpansionPanel
          title={'Date & Time'}
          expanded={expanded.thirdPanel}
          tabIndex={2}
          onAction={(event: ExpansionPanelActionEvent) => {
            setExpanded({ ...expanded, thirdPanel: !expanded.thirdPanel });
          }}
        >
          <div>
            {expanded.thirdPanel}
            {expanded.thirdPanel && (
              <ExpansionPanelContent>
                {props.editMode ?
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Status</div>
                        <Checkbox label={'Enable'} value={ntpEnabled} onChange={(ev) => setNtpEnabled(ev.value)}></Checkbox>
                      </div>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Server</div>
                        <div className='k-form-field-wrap'>
                          <Input type='text' value={ntpServer}
                                 onChange={(ev) => {
                                   setNtpServer(ev.value);
                                 }} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Daylight Saving</div>
                        <Checkbox label={'Enable'} value={ntpDst} onChange={(ev) => setNtpDst(ev.value)}></Checkbox>
                      </div>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Timezone</div>
                        <DropDownList
                          data={TimeZoneList}
                          textField="text"
                          dataItemKey="value"
                          value={ntpTimezone}
                          onChange={(ev) => setNtpTimezone(ev.value)}
                        />
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Status'} value={ntpEnabled}></DefaultViewControl>
                      </div>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Server'} value={ntpServer}></DefaultViewControl>
                      </div>
                    </div>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Daylight Saving'} value={ntpDst}></DefaultViewControl>
                      </div>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Timezone'} value={ntpTimezone?.text}></DefaultViewControl>
                      </div>
                    </div>
                  </>
                }
              </ExpansionPanelContent>
            )}
          </div>
        </ExpansionPanel>
        {props.editMode ?
          <div className={styles.actionButtonsBox + ' ' + 'k-form-buttons'}>
            <button
              type={'button'}
              className={styles.actionMainButton}
              onClick={() => save()}
            >
              Save
            </button>
            <button
              type={'button'}
              className={styles.actionButtonGray}
              onClick={() => cancel()}
            >
              Cancel
            </button>
          </div> : null
        }
      </div>
    </>
  );
};

export default IntercomConfigSystem;
