import { FunctionComponent } from 'react';
import styles from './DefaultViewCoontrol.module.scss';
import { Checkbox } from '@progress/kendo-react-inputs';
interface Props {
  label: string | undefined;
  value: string | boolean | number | undefined;
  checkboxView?: boolean | undefined;
  checkboxLabel?: string | undefined;
  passwordView?: boolean | undefined;
}

const DefaultViewControl: FunctionComponent<Props> = (props: Props) => {
  return(
    <>
      {props.checkboxView ?
        <div className={styles.viewWrapper}>
          <div className={styles.labelView}>{props.label}</div>
          <Checkbox disabled={true} label={props.checkboxLabel} value={props.value}></Checkbox>
        </div>
        :
        <div className={styles.viewWrapper}>
          <div className={styles.labelView}>{props.label}</div>
          <div className={styles.valueView}>{props.passwordView
          && props.value && String(props.value).length > 0 ? '*****' : props.value}</div>
        </div>
      }
    </>

  )
}

export default DefaultViewControl;
