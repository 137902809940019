import { FunctionComponent, useEffect, useState } from 'react';
import { ConfigContentPropsInterface } from '../ConfigContentPropsInterface';
import styles from '../IntercomConfigMenuContent.module.scss';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import DefaultViewControl from '../../../../../components/common/defaultViewControl/DefaultViewControl';

const IntercomConfigNetwork: FunctionComponent<ConfigContentPropsInterface> = (props: ConfigContentPropsInterface) => {
  const [expanded, setExpanded] = useState({
    firstPanel: true,
    secondPanel: true,
    thirdPanel: true,
  });
  const [wanHost, setWanHost] = useState<string>();
  const [wanDomain, setWanDomain] = useState<string>();
  const [wanMode, setMode] = useState<string>();
  const [wanIpv6, setIpv6] = useState<boolean>();
  const [wanMtuSize, setMtuSize] = useState<number>();
  const [wanForce10mbps, setForce10mbps] = useState<boolean>();
  const [networkStunPort, setNetworkStunPort] = useState<string>();
  const [networkStunServer, setNetworkStunServer] = useState<string>();
  const [voipSipPortRangeBegin, setVoipSipPortRangeBegin] = useState<any>();
  const [voipSipPortRangeEnd, setVoipSipPortRangeEnd] = useState<any>();

  useEffect(() => {
    setValues(props.model)
  }, []);
  if (!props.model) {
    return (
      <></>
    );
  }

  const setValues = (model: any) => {
    if (model && model.network && model.network.stun && model.voip
      && model.voip.sip && model.voip.sip.rtp_port_range) {
      const wan = model.network.wan;
      const wanIpv6 = model.network.wan.ipv6;
      const stun = model.network.stun;
      const rtpPortRange = model.voip.sip.rtp_port_range;
      setWanHost(wan.host);
      setWanDomain(wan.domain);
      setMode(wan.mode);
      setIpv6(wanIpv6.enabled);
      setMtuSize(wan.mtu_size);
      setForce10mbps(wan.force_10_mbps);
      setNetworkStunPort(stun.port);
      setNetworkStunServer(stun.server);
      setVoipSipPortRangeBegin(rtpPortRange.begin);
      setVoipSipPortRangeEnd(rtpPortRange.end);
    }
  }
  const save = () => {
    let updateModel = {
      network: {
        stun: {
          port: networkStunPort,
          server: networkStunServer,
        },
        wan: {
          host: wanHost,
          domain: wanDomain,
          mode: wanMode,
          ipv6: {
            enabled: wanIpv6
          },
          mtu_size: wanMtuSize,
          force_10_mbps: wanForce10mbps,
        },
      },
      voip: {
        sip: {
          rtp_port_range: {
            begin: voipSipPortRangeBegin,
            end: voipSipPortRangeEnd,
          },
        },
      },
    };
    let updateConfigModel = {Config: updateModel, IsPartial: true}
    props.saveEvent(updateConfigModel);
  };

  const cancel = () => {
    setValues(props.model)
    props.cancelEvent();
  };
  return (
    <>
      <div className={styles.pageWrapperIntercomConfigContent} id={'IntercomConfigContentExpander'}>
        <div className={styles.panelRowSpaceBetween} style={{ margin: 10 }}>
          <div className={styles.pageTitle}>Network</div>
        </div>
        <ExpansionPanel
          title={'WAN'}
          expanded={expanded.firstPanel}
          tabIndex={0}
          onAction={(event: ExpansionPanelActionEvent) => {
            setExpanded({ ...expanded, firstPanel: !expanded.firstPanel });
          }}
        >
          <div>
            {expanded.firstPanel}
            {expanded.firstPanel && (
              <ExpansionPanelContent>
                {props.editMode ?
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Host</div>
                        <div className='k-form-field-wrap'>
                          <Input type='text' value={wanHost}
                                 onChange={(ev) => {
                                   setWanHost(ev.value);
                                 }} />
                        </div>
                      </div>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Domain</div>
                        <div className='k-form-field-wrap'>
                          <Input type='text' value={wanDomain}
                                 onChange={(ev) => {
                                   setWanDomain(ev.value);
                                 }} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Connection Type</div>
                        <div>
                          <input type='radio' id='connectionModeDHCP' name="group1"
                                 value={'DHCP'} onChange={() => setMode('DHCP')} checked={wanMode === 'DHCP'} />
                          <label className={styles.labelRadio} htmlFor='connectionModeDHCP'>Dynamic IP</label>
                          <input type='radio' value={'static'} id='connectionModeStatic' checked={wanMode === 'static'} name="group1"
                                 onChange={() => setMode('static')} />
                          <label className={styles.labelRadio} htmlFor='connectionModeStatic'>Static IP</label>
                        </div>
                      </div>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>IPV6</div>
                        <Checkbox label={'Enable'} value={wanIpv6} onChange={(ev) => setIpv6(ev.value)}>
                        </Checkbox>
                      </div>
                    </div>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Mtu size (advanced)</div>
                        <div className='k-form-field-wrap'>
                          <Input type='text' value={wanMtuSize}
                                 onChange={(ev) => {
                                   setMtuSize(Number(ev.value));
                                 }} />
                        </div>
                      </div>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Force Slower 10 Mbps link speed (advanced)</div>
                        <Checkbox label={'Enable'} value={wanForce10mbps} onChange={(ev) => setForce10mbps(ev.value)}>
                        </Checkbox>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Host'} value={wanHost}></DefaultViewControl>
                      </div>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Domain'} value={wanDomain}></DefaultViewControl>
                      </div>
                    </div>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Connection Type'} value={wanMode}></DefaultViewControl>
                      </div>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'IPv6'} value={wanIpv6}></DefaultViewControl>
                      </div>
                    </div>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Mtu size (advanced)'} value={wanMtuSize}></DefaultViewControl>
                      </div>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Force Slower 10 Mbps link speed (advanced)'} value={wanForce10mbps}></DefaultViewControl>
                      </div>
                    </div>
                  </>
                }
              </ExpansionPanelContent>
            )}
          </div>
        </ExpansionPanel>
        <ExpansionPanel
          title={'STUN'}
          expanded={expanded.secondPanel}
          tabIndex={1}
          onAction={(event: ExpansionPanelActionEvent) => {
            setExpanded({ ...expanded, secondPanel: !expanded.secondPanel });
          }}
        >
          <div>
            {expanded.secondPanel}
            {expanded.secondPanel && (
              <ExpansionPanelContent>
                {props.editMode ?
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Port</div>
                        <div className='k-form-field-wrap'>
                          <Input type='number' value={networkStunPort}
                                 onChange={(ev) => {
                                   setNetworkStunPort(ev.value);
                                 }} />
                        </div>
                      </div>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Server</div>
                        <div className='k-form-field-wrap'>
                          <Input type='text' value={networkStunServer}
                                 onChange={(ev) => {
                                   setNetworkStunServer(ev.value);
                                 }} />
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Port'} value={networkStunPort}></DefaultViewControl>
                      </div>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Server'} value={networkStunServer}></DefaultViewControl>
                      </div>
                    </div>
                  </>
                }
              </ExpansionPanelContent>
            )}
          </div>
        </ExpansionPanel>
        <ExpansionPanel
          title={'RTP'}
          expanded={expanded.thirdPanel}
          tabIndex={0}
          onAction={(event: ExpansionPanelActionEvent) => {
            setExpanded({ ...expanded, thirdPanel: !expanded.thirdPanel });
          }}
        >
          <div>
            {expanded.thirdPanel}
            {expanded.thirdPanel && (
              <ExpansionPanelContent>
                {props.editMode ?
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Port Range Start</div>
                        <div className='k-form-field-wrap'>
                          <Input type='number' value={voipSipPortRangeBegin}
                                 onChange={(ev) => {
                                   setVoipSipPortRangeBegin(ev.value);
                                 }} />
                        </div>
                      </div>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Port Range End</div>
                        <div className='k-form-field-wrap'>
                          <Input type='number' value={voipSipPortRangeEnd}
                                 onChange={(ev) => {
                                   setVoipSipPortRangeEnd(ev.value);
                                 }} />
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Port Range Start'} value={voipSipPortRangeBegin}></DefaultViewControl>
                      </div>
                      <div className={styles.viewWrapper}>
                        <DefaultViewControl label={'Port Range End'} value={voipSipPortRangeEnd}></DefaultViewControl>
                      </div>
                    </div>
                  </>
                }
              </ExpansionPanelContent>
            )}
          </div>
        </ExpansionPanel>
        {props.editMode ?
          <div className={styles.actionButtonsBox + ' ' + 'k-form-buttons'}>
            <button
              type={'button'}
              className={styles.actionMainButton}
              onClick={() => save()}
            >
              Save
            </button>
            <button
              type={'button'}
              className={styles.actionButtonGray}
              onClick={() => cancel()}
            >
              Cancel
            </button>
          </div> : null
        }
      </div>
    </>
  );
};

export default IntercomConfigNetwork;
