import { FunctionComponent } from 'react';
import styles from './InfoCard.module.scss';
interface FlatData {
  name: string;
  value: any;
}
interface Props {
  title: string;
  data: FlatData[];
  width?: number;
  height?: number;
}
const InfoCard: FunctionComponent<Props> = (props: Props) => {
  return(
    <div className={styles.cardWrapper}
         style={{width: props.width ? props.width : 300,
           height: props.height ? props.height : 100}}>
      <div className={styles.cardTitle}>
        {props.title}
      </div>
      {props.data ? props.data.map((d, index) => {
        return(
          <div key={index}>
            <span className={styles.cardDataName}>{d.name + ':'}</span>
            <span>{d.value}</span>
          </div>
        )
      }) : null}
    </div>
  )
}

export default InfoCard
