import { FunctionComponent, useEffect, useState } from 'react';
import { ConfigContentPropsInterface } from '../ConfigContentPropsInterface';
import styles from '../IntercomConfigMenuContent.module.scss';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import DefaultViewControl from '../../../../../components/common/defaultViewControl/DefaultViewControl';

const IntercomConfigAccounts: FunctionComponent<ConfigContentPropsInterface> = (props: ConfigContentPropsInterface) => {
  const [expanded, setExpanded] = useState({
    firstPanel: true,
    secondPanel: true,
    thirdPanel: true,
    fourthPanel: true
  });
  const [intercomConfig, setIntercomConfig] = useState<any>();
  const [accountLabel, setAccountLabel] = useState<string>();
  const [accountName, setAccountName] = useState<string>();
  const [accountUser, setAccountUser] = useState<string>();
  const [accountSipDomain, setAccountSipDomain] = useState<string>();
  const [accountSipRegister, setAccountSipRegister] = useState<boolean>();
  const [accountSipAuthenticationPassword, setAccountSipAuthenticationPassword] = useState<string>();
  const [sipTransportMode, setSipTransportMode] = useState<string>();
  const [sipLocalPort, setSipLocalPort] = useState<string>();
  const [sipSTUN, setSipSTUN] = useState<boolean>();
  const [sipDscp, setSipDscp] = useState<string>();
  const [sipRtpDscp, setSipRtpDscp] = useState<string>();
  const [sipAuthenticationUsername, setSipAuthenticationUsername] = useState<string>();
  const [sipProxyAddress, setSipProxyAddress] = useState<string>();
  const [sipProxyPort, setSipProxyPort] = useState<string>();
  const [sipRegistrationLifeTime, setSipRegistrationLifeTime] = useState<string>();
  const [sipKeepAliveEnabled, setSipKeepAliveEnabled] = useState<boolean>();
  const [sipRtpAudioEncoderSilenceSuppression, setSipRtpAudioEncoderSilenceSuppression] = useState<boolean>();
  const [accountCallTimersAttemptTimeout, setAccountCallTimersAttemptTimeout] = useState<string>();
  const [accountCallTimersActiveTimeout, setAccountCallTimersActiveTimeout] = useState<string>();

  useEffect(() => {
    setValues(props.model);
  }, []);

  if (!props.model) {
    return (
      <></>
    );
  }

  const setValues = (model: any) => {
    if (model && model.voip && model.voip.accounts && model.voip.accounts.length > 0
      && model.voip.accounts[0].sip && model.voip.accounts[0].sip.authentication
    && model.voip.accounts[0].sip.rtp && model.voip.accounts[0].sip.proxy
      && model.voip.accounts[0].sip.keep_alive && model.voip.accounts[0].sip.rtp.audio_encoder
    && model.voip.accounts[0].call && model.voip.accounts[0].call.timers) {
      setIntercomConfig(model)
      const account = model.voip.accounts[0];
      const accountSip = model.voip.accounts[0].sip;
      const accountSipAuthentication = model.voip.accounts[0].sip.authentication;
      const accountSipRtp = model.voip.accounts[0].sip.rtp;
      const accountSipProxy = model.voip.accounts[0].sip.proxy;
      const accountSipKeepAlive = model.voip.accounts[0].sip.keep_alive;
      const accountSipAudioEncoder = model.voip.accounts[0].sip.rtp.audio_encoder;
      const accountCallTimers = model.voip.accounts[0].call.timers;
      setAccountLabel(account.label);
      setAccountName(account.name);
      setAccountUser(account.user);
      setAccountSipDomain(accountSip.domain);
      setAccountSipRegister(accountSip.register);
      setAccountSipAuthenticationPassword(accountSipAuthentication.password);
      setSipTransportMode(accountSip.transport);
      setSipLocalPort(accountSip.local_port);
      setSipSTUN(accountSip.stun);
      setSipDscp(accountSip.dscp);
      setSipRtpDscp(accountSipRtp.dscp);
      setSipAuthenticationUsername(accountSipAuthentication.username);
      setSipProxyAddress(accountSipProxy.address);
      setSipProxyPort(accountSipProxy.port);
      setSipRegistrationLifeTime(accountSip.registration_lifetime);
      setSipKeepAliveEnabled(accountSipKeepAlive.enabled);
      setSipRtpAudioEncoderSilenceSuppression(accountSipAudioEncoder.silence_suppression);
      setAccountCallTimersAttemptTimeout(accountCallTimers.attempt_timeout);
      setAccountCallTimersActiveTimeout(accountCallTimers.active_timeout);
    }
  }
  const save = () => {
    let updateModel = {
      intercom:
        {
          voip: {
            accounts: [
              {
                name: accountName,
                label: accountLabel,
                user: accountUser,
                call: {
                  timers: {
                    active_timeout: accountCallTimersActiveTimeout,
                    attempt_timeout: accountCallTimersAttemptTimeout
                  }
                },
                sip: {
                  domain: accountSipDomain,
                  register: accountSipRegister,
                  authentication: {
                    password: accountSipAuthenticationPassword,
                    username: sipAuthenticationUsername
                  },
                  transport: sipTransportMode,
                  local_port: sipLocalPort,
                  stun: sipSTUN,
                  dscp: sipDscp,
                  rtp: {
                    dscp: sipRtpDscp,
                    audio_recorder: {
                      silence_suppression: sipRtpAudioEncoderSilenceSuppression
                    }
                  },
                  proxy: {
                    address: sipProxyAddress,
                    port: sipProxyPort,
                  },
                  registration_lifetime: sipRegistrationLifeTime,
                  keep_alive: {
                    enabled: sipKeepAliveEnabled,
                  },

                }
              }
            ]
          }
        },
    };
    let mergedIntercomConfig = Object.assign(intercomConfig, updateModel);
    let updateConfigModel = {Config: mergedIntercomConfig, IsPartial: false}
    props.saveEvent(updateConfigModel);
  };

  const cancel = () => {
    setValues(props.model);
    props.cancelEvent();
  };

  return(
    <div className={styles.pageWrapperIntercomConfigContent} id={'IntercomConfigContentExpander'}>
      <div className={styles.panelRowSpaceBetween} style={{ margin: 10 }}>
        <div className={styles.pageTitle}>Accounts</div>
      </div>
      <ExpansionPanel
        title={'General'}
        expanded={expanded.firstPanel}
        tabIndex={0}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, firstPanel: !expanded.firstPanel });
        }}
      >
        <div>
          {expanded.firstPanel}
          {expanded.firstPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Account Name</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={accountLabel}
                               onChange={(ev) => {
                                 setAccountLabel(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Display Name</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={accountName}
                               onChange={(ev) => {
                                 setAccountName(ev.value);
                               }} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Username / Number</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={accountUser}
                               onChange={(ev) => {
                                 setAccountUser(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Password</div>
                      <div className='k-form-field-wrap'>
                        <Input type='password' value={accountSipAuthenticationPassword}
                               onChange={(ev) => {
                                 setAccountSipAuthenticationPassword(ev.value);
                               }} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Domain</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={accountSipDomain}
                               onChange={(ev) => {
                                 setAccountSipDomain(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Register with Domain</div>
                      <Checkbox label={'Enable'} value={accountSipRegister} onChange={(ev) => setAccountSipRegister(ev.value)}> </Checkbox>
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Account Name'} value={accountLabel}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Domain Name'} value={accountName}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Username / Number'} value={accountUser}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl passwordView={true} label={'Password'} value={accountSipAuthenticationPassword}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Domain'} value={accountSipDomain}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Register with Domain'} value={accountSipRegister}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Topology'}
        expanded={expanded.secondPanel}
        tabIndex={1}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, secondPanel: !expanded.secondPanel });
        }}
      >
        <div>
          {expanded.secondPanel}
          {expanded.secondPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Sip Transport Mode</div>
                      <div>
                        <input type='radio' id='transportAuto' name="group1"
                               value={'auto'} onChange={() => setSipTransportMode('auto')} checked={sipTransportMode === 'auto'} />
                        <label className={styles.labelRadio} htmlFor='transportAuto'>Automatic</label>
                        <input type='radio' value={'tcp'} id='transportTcp' checked={sipTransportMode === 'tcp'} name="group1"
                               onChange={() => setSipTransportMode('tcp')} />
                        <label className={styles.labelRadio} htmlFor='transportTcp'>TCP</label>
                        <input type='radio' value={'udp'} id='transportUdp' checked={sipTransportMode === 'udp'} name="group1"
                               onChange={() => setSipTransportMode('udp')} />
                        <label className={styles.labelRadio} htmlFor='transportUdp'>UDP only</label>
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Local Port</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={sipLocalPort}
                               onChange={(ev) => {
                                 setSipLocalPort(ev.value);
                               }} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>STUN</div>
                      <Checkbox label={'Enable'} value={sipSTUN} onChange={(ev) => setSipSTUN(ev.value)}> </Checkbox>
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Sip Transport Mode'} value={sipTransportMode}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Local Port'} value={sipLocalPort}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'STUN'} value={sipSTUN}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'QoS'}
        expanded={expanded.thirdPanel}
        tabIndex={2}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, thirdPanel: !expanded.thirdPanel });
        }}
      >
        <div>
          {expanded.thirdPanel}
          {expanded.thirdPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>SIP</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={sipDscp}
                               onChange={(ev) => {
                                 setSipDscp(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>RTP Audio</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={sipRtpDscp}
                               onChange={(ev) => {
                                 setSipRtpDscp(ev.value);
                               }} />
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'SIP'} value={sipDscp}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'RTP Audio'} value={sipRtpDscp}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Advanced'}
        expanded={expanded.fourthPanel}
        tabIndex={3}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, fourthPanel: !expanded.fourthPanel });
        }}
      >
        <div>
          {expanded.fourthPanel}
          {expanded.fourthPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Auth Username</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={sipAuthenticationUsername}
                               onChange={(ev) => {
                                 setSipAuthenticationUsername(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Proxy</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={sipProxyAddress}
                               onChange={(ev) => {
                                 setSipProxyAddress(ev.value);
                               }} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Proxy Port</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={sipProxyPort}
                               onChange={(ev) => {
                                 setSipProxyPort(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Registration lifetime (seconds)</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={sipRegistrationLifeTime}
                               onChange={(ev) => {
                                 setSipRegistrationLifeTime(ev.value);
                               }} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Keep Alive</div>
                      <Checkbox label={'Enable'} value={sipKeepAliveEnabled} onChange={(ev) => setSipKeepAliveEnabled(ev.value)}> </Checkbox>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Silence Suppression</div>
                      <Checkbox label={'Enable'} value={sipRtpAudioEncoderSilenceSuppression} onChange={(ev) => setSipRtpAudioEncoderSilenceSuppression(ev.value)}> </Checkbox>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Call Attempt Timeout (seconds)</div>
                      <Input type='text' value={accountCallTimersAttemptTimeout} onChange={(ev) => setAccountCallTimersAttemptTimeout(ev.value)} />
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Call Active Timeout (seconds)</div>
                      <Input type='text' value={accountCallTimersActiveTimeout} onChange={(ev) => setAccountCallTimersActiveTimeout(ev.value)} />
                    </div>
                  </div>

                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Auth Username'} value={sipAuthenticationUsername}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Proxy'} value={sipProxyAddress}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Proxy Port'} value={sipProxyPort}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Registration lifetime (seconds)'} value={sipRegistrationLifeTime}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Keep Alive'} value={sipKeepAliveEnabled}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Silence Suppression'} value={sipRtpAudioEncoderSilenceSuppression}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Call Attempt Timeout (seconds)'} value={accountCallTimersAttemptTimeout}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl  label={'Call Active Timeout (seconds)'} value={accountCallTimersActiveTimeout}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      {props.editMode ?
        <div className={styles.actionButtonsBox + ' ' + 'k-form-buttons'}>
          <button
            type={'button'}
            className={styles.actionMainButton}
            onClick={() => save()}
          >
            Save
          </button>
          <button
            type={'button'}
            className={styles.actionButtonGray}
            onClick={() => cancel()}
          >
            Cancel
          </button>
        </div> : null
      }
    </div>

  )
}

export default IntercomConfigAccounts;
