import { FunctionComponent, useState } from 'react';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import styles from './IntercomConfigMenu.module.scss';


export interface IMenuItem {
  id: number;
  name: string;
  children?: IMenuItem[];
  parentId?: number;
}

interface Props {
  eventItemSelected: any;
  menuItems: IMenuItem[];
  defaultSelectedItem?: IMenuItem;
}

const IntercomConfigMenu: FunctionComponent<Props> = (props: Props) => {
  const [expanded, setExpanded] = useState(1);
  const [selectedMenuItem, setSelectedMenuItem] = useState<IMenuItem | null>(props.defaultSelectedItem || null);
  const sendSelectEvent = (event: any, menuItem: IMenuItem) => {
    setSelectedMenuItem(menuItem)

    if (menuItem && menuItem.children && menuItem.children.length > 0) {
      return;
    }

    props.eventItemSelected(menuItem)
  }
  return(
    <div id={'IntercomConfigMenu'}>
      {props.menuItems.map((item: IMenuItem) => {
        if (item.children) {
          return(
            <div key={item.id} id={'IntercomConfigMenuParent'}>
              <ExpansionPanel
                title={item.name}
                expanded={expanded === item.id}
                tabIndex={0}
                key={item.id}
                onAction={(event: ExpansionPanelActionEvent) => {
                  setExpanded(event.expanded ? 0 : item.id);
                }}
                style={selectedMenuItem && selectedMenuItem.parentId === item.id ? {backgroundColor: '#DEDEDE'} : undefined}
              >
                <Reveal key={item.id}>
                  {expanded === item.id && (
                    <ExpansionPanelContent key={item.id}>
                      {item.children.map((subItem: IMenuItem) => {
                        return(
                          <div id={'IntercomConfigMenuItem'}
                               key={subItem.id}
                               className={'k-expander'}
                               style={selectedMenuItem && selectedMenuItem.id === subItem.id ? {backgroundColor: '#DEDEDE'} : undefined}
                               onClick={(event) => sendSelectEvent(event, subItem)}>
                            {subItem.name}
                          </div>
                        )
                      })}
                    </ExpansionPanelContent>
                  )}
                </Reveal>
              </ExpansionPanel>
            </div>
          )
        }
        if (item.id) {
          return(
            <div id={'IntercomConfigMenuItem'}
                 key={item.id}
                 className={'k-expander'}
                 style={selectedMenuItem && selectedMenuItem.id === item.id ? {backgroundColor: '#DEDEDE'} : undefined}
                 onClick={(event) => sendSelectEvent(event, item)}>
              {item.name}
            </div>
          )
        }
        else {
          return null;
        }
      })}
    </div>
  )
}

export default IntercomConfigMenu;
