import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiClient, notifications, UpgradeConfigMode } from '../../../services';
import styles from './IntercomInfoPage.module.scss';
import InfoCard from '../../../components/common/InfoCard/InfoCard';
import { AxiosResponse } from 'axios/index';
import IntercomConfigMenu, { IMenuItem } from './intercomConfigMenu/IntercomConfigMenu';
import IntercomConfigCalls from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigCalls';
import IntercomConfigButtons from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigButtons';
import IntercomConfigDoor from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigDoor';
import IntercomConfigAccounts from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigAccounts';
import IntercomConfigAudio from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigAudio';
import IntercomConfigNetwork from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigNetwork';
import IntercomConfigManagement from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigManagement';
import EditIcon from '../../../assets/icons/editIcon.svg';
import DownloadIcon from '../../../assets/icons/downloadIcon.svg';
import { JSONToFileService } from '../../../services/jsonToFile-service';
import IntercomConfigSystem from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigSystem';

const MenuItems: IMenuItem[] = [
  {id: 1, name: 'Call'},
  {id: 2, name: 'Buttons / Light'},
  {id: 3, name: 'Door'},
  {id: 4, name: 'Accounts'},
  {id: 5, name: 'Audio'},
  {id: 6, name: 'Network'},
  {id: 7, name: 'System'},
  {id: 8, name: 'Management'},
]

const defaultMenuItem = {id: 1, name: 'Call'};

const IntercomInfoPage: FunctionComponent = () => {
  const currentLoc = useLocation();
  const intercomId: string = currentLoc.pathname.split('/')[2];
  const [intercomConfig, setIntercomConfig] = useState<any>();
  const [selectedMenuItem, setSelectedMenuItem] = useState<any>(defaultMenuItem);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [doorStatus, setDoorStatus] = useState<any>();
  const [user, setUser] = useState<any>();
  const [system, setSystem] = useState<any>();

  const selectMenuItem = (event: any) => {
    setSelectedMenuItem(event)
  }

  useEffect(() => {
    apiClient().intercoms.getConfigById(intercomId).then((response: AxiosResponse<any>) => {
      prepareData(response.data)
    });
  }, [])

  const updateIntercomConfig = (updatedPartOfIntercomConfig: UpgradeConfigMode) => {
    if (updatedPartOfIntercomConfig) {
      apiClient().intercoms.updateConfig(intercomId, updatedPartOfIntercomConfig).then((response) => {
        if (response.data) {
          notifications.success();
          setEditMode(false);
          apiClient().intercoms.getConfigById(intercomId).then((response: AxiosResponse<any>) => {
            prepareData(response.data)
          });
        }
      })
    }
  }

  const downloadIntercomConfig = () => {
    apiClient().intercoms.downloadConfig(intercomId).then((data) => {
      JSONToFileService.JSONToFile(data.data, `intercom_config_${intercomId}`);
    })
  }

  const prepareData = (data: any) => {
    setIntercomConfig(data);
    console.log(data);
    if (data.intercom.door) {
      let doorStatusData = [
        {name: 'Lock', value: data.intercom.door.permanent_state === 'locked' ? 'Permanently Locked' : 'Permanently Open'},
        {name: 'Door', value: data.intercom.door.permanent_state === 'locked' ? 'Closed' : 'Open'},
      ]
      setDoorStatus(doorStatusData)
    }

    let user = [
      {name: 'User', value: data.voip.accounts[0].user},
      {name: 'Status', value: data.voip.accounts[0].enable ? 'Open' : 'Closed'},
    ]
    setUser(user);

    let system = [
      {name: 'WAN', value: `${data.network.wan.mode}`},
      {name: 'MAC Address', value: intercomId},
    ]

    setSystem(system);
  }

  return (
    <div className={styles.pageWrapper + ' ' + styles.pageWrapperIntercom}>
      <div className={styles.panelRowSpaceBetween} style={{marginBottom: 20}}>
        <div className={styles.pageTitle}>Intercom {intercomId}</div>
        {!editMode ?
          <div className={styles.panelRowSpaceBetween} style={{gap: 20}}>
            <button className={styles.actionButton} onClick={downloadIntercomConfig}>
              <img className={styles.buttonIconPosition} src={DownloadIcon} alt='downloadIcon' />
              Download
            </button>
            <button className={styles.actionButton} onClick={() => setEditMode(true)}>
              <img className={styles.buttonIconPosition} src={EditIcon} alt='edit' />
              Edit
            </button>
          </div>
          : null}
      </div>
      <div className={styles.infoCardsGroup}>
        {doorStatus ? <InfoCard title={'Door Status'} data={doorStatus}></InfoCard> : null}
        {user ? <InfoCard title={'User'} data={user}></InfoCard> : null}
        {system ? <InfoCard title={'System'} data={system}></InfoCard> : null}
      </div>
      <div className={styles.infoPageContent}>
        <IntercomConfigMenu eventItemSelected={selectMenuItem}
                            defaultSelectedItem={defaultMenuItem}
                            menuItems={MenuItems}></IntercomConfigMenu>
        <div className={styles.infoPageMenuContent}>
          { selectedMenuItem.id === 1 && intercomConfig?.intercom?.call ? <IntercomConfigCalls model={intercomConfig}
                                                              cancelEvent={() => setEditMode(false)}
                                                              saveEvent={(ev: any) => updateIntercomConfig(ev)}
                                                              editMode={editMode}></IntercomConfigCalls> : null}
          { selectedMenuItem.id === 2 ? <IntercomConfigButtons
            model={intercomConfig}
            cancelEvent={() => setEditMode(false)}
            saveEvent={(ev: any) => updateIntercomConfig(ev)}
            editMode={editMode}
          ></IntercomConfigButtons> : null}
          { selectedMenuItem.id === 3 ? <IntercomConfigDoor
            model={intercomConfig?.intercom?.door}
            cancelEvent={() => setEditMode(false)}
            saveEvent={(ev: any) => updateIntercomConfig(ev)}
            editMode={editMode}
          ></IntercomConfigDoor> : null}
          { selectedMenuItem.id === 4 ? <IntercomConfigAccounts
            model={intercomConfig}
            cancelEvent={() => setEditMode(false)}
            saveEvent={(ev: any) => updateIntercomConfig(ev)}
            editMode={editMode}
          ></IntercomConfigAccounts> : null}
          { selectedMenuItem.id === 5 ? <IntercomConfigAudio
            model={intercomConfig}
            cancelEvent={() => setEditMode(false)}
            saveEvent={(ev: any) => updateIntercomConfig(ev)}
            editMode={editMode}
          ></IntercomConfigAudio> : null}
          { selectedMenuItem.id === 6 ? <IntercomConfigNetwork
            model={intercomConfig}
            cancelEvent={() => setEditMode(false)}
            saveEvent={(ev: any) => updateIntercomConfig(ev)}
            editMode={editMode}
          ></IntercomConfigNetwork>: null}
          { selectedMenuItem.id === 7 ? <IntercomConfigSystem
            model={intercomConfig?.system}
            cancelEvent={() => setEditMode(false)}
            saveEvent={(ev: any) => updateIntercomConfig(ev)}
            editMode={editMode}
          ></IntercomConfigSystem> : null}
          { selectedMenuItem.id === 8 ? <IntercomConfigManagement
            model={intercomId}
            cancelEvent={() => setEditMode(false)}
            saveEvent={(ev: any) => updateIntercomConfig(ev)}
            editMode={editMode}></IntercomConfigManagement> : null}
        </div>
      </div>
    </div>
  )
}

export default IntercomInfoPage;
