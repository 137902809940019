import { FunctionComponent, useEffect, useState } from 'react';
import { ConfigContentPropsInterface } from '../ConfigContentPropsInterface';
import styles from '../IntercomConfigMenuContent.module.scss';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import DefaultViewControl from '../../../../../components/common/defaultViewControl/DefaultViewControl';
import { apiClient, notifications } from '../../../../../services';
import { Intercom, IUpgradeActionIntercom } from '../../../../../model/interfaces/intercom/intercom';
import { AxiosResponse } from 'axios/index';
import { Firmware } from '../../../../../model/interfaces/firmware';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const IntercomConfigManagement: FunctionComponent<ConfigContentPropsInterface> = (props: ConfigContentPropsInterface) => {
  const [expanded, setExpanded] = useState({ firstPanel: true });
  const [intercom, setIntercom] = useState<Intercom>();
  const [currentFirmwareVersion, setCurrentFirmwareVersion] = useState<any>();
  const [firmwareVersions, setFirmwareVersions] = useState<any>();
  useEffect(() => {
    if (props.model) {
      apiClient().intercoms.getIntercomByWanMac(props.model).then((response) => {
        setIntercom(response.data)
        apiClient().firmware.getAll().then((resp: AxiosResponse<Firmware[]>) => {
          let mappedFirmwares: any[] = resp.data.map((fw: Firmware) => {
            return { Id: fw.Id, Version: fw.Version };
          }).filter((mf) => mf.Version !== response.data.Firmware);
          setCurrentFirmwareVersion(mappedFirmwares[0]);
          setFirmwareVersions(mappedFirmwares);
        });
      })

    }
  }, [])
  if (!props.model) {
    return (
      <></>
    );
  }

  const upgradeIntercom = () => {
    let upgradeIntercomModel: IUpgradeActionIntercom = {
      WanMac: intercom?.WanMac,
      Sip: intercom?.SipAddress,
      Id: currentFirmwareVersion?.Id,
    };
    apiClient().intercoms.upgrade(props.model, upgradeIntercomModel).then((response: AxiosResponse<boolean>) => {
      notifications.success();
    }, (er) => {
      console.log(er);
    });
  };

  const save = () => {
    upgradeIntercom();
  };

  const cancel = () => {
    props.cancelEvent();
  };
  return (
    <div className={styles.pageWrapperIntercomConfigContent} id={'IntercomConfigContentExpander'}>
      <div className={styles.panelRowSpaceBetween} style={{ margin: 10 }}>
        <div className={styles.pageTitle}>Management</div>
      </div>
      <ExpansionPanel
        title={'Firmware'}
        expanded={expanded.firstPanel}
        tabIndex={0}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, firstPanel: !expanded.firstPanel });
        }}
      >
        <div>
          {expanded.firstPanel}
          {expanded.firstPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div className={styles.panelRowSpaceBetweenCustom}>
                  <div className={styles.viewWrapper}>
                    <div className={styles.labelView}>Firmware Version</div>
                    <DropDownList
                      data={firmwareVersions}
                      textField="Version"
                      dataItemKey="Id"
                      value={currentFirmwareVersion}
                      onChange={(ev) => setCurrentFirmwareVersion(ev.value)}
                    />
                  </div>
                </div>
                :
                <div className={styles.panelRowSpaceBetweenCustom}>
                  <div className={styles.viewWrapper}>
                    <DefaultViewControl label={'Firmware Version'} value={intercom?.Firmware}></DefaultViewControl>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      {props.editMode ?
        <div className={styles.actionButtonsBox + ' ' + 'k-form-buttons'}>
          <button
            type={'button'}
            className={styles.actionMainButton}
            onClick={() => save()}
          >
            Save
          </button>
          <button
            type={'button'}
            className={styles.actionButtonGray}
            onClick={() => cancel()}
          >
            Cancel
          </button>
        </div> : null
      }
    </div>
  );
};

export default IntercomConfigManagement;
