import { FunctionComponent, useEffect, useState } from 'react';
import styles from '../IntercomConfigMenuContent.module.scss';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { ConfigContentPropsInterface } from '../ConfigContentPropsInterface';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import DefaultViewControl from '../../../../../components/common/defaultViewControl/DefaultViewControl';

const RepeatFunction = [
  { text: 'does nothing', value: 'none' },
  { text: 'hangs up, but only after the call has been connected', value: 'connected_hangup' },
  { text: 'always hangs up the call', value: 'hangup' },
];
const SecondaryFunction = [
  { text: 'Dial a number', value: 'dial' },
  { text: 'Unlock door from inside', value: 'unlock' },
  { text: 'Send a sequence of DTMF keys in a call', value: 'dtmf' },
];

const LightsFunctions = [
  { text: 'Unused / not present', value: 'no_function' },
  { text: 'When call is in progress (off-hook)', value: 'call_progress' },
  { text: 'When call is successful', value: 'call_active' },
  { text: 'When call is in progress and successful', value: 'call_duration' },
  { text: 'When one or more accounts are attempting to register', value: 'account_any_registering' },
  { text: 'When one or more accounts are registered', value: 'account_any_registered' },
  { text: 'When ALL account(s) are registered', value: 'account_all_registered' },
];

const IntercomConfigButtons: FunctionComponent<ConfigContentPropsInterface> = (props: ConfigContentPropsInterface) => {
  const [expanded, setExpanded] = useState({ firstPanel: true, secondPanel: true, thirdPanel: true });
  const [intercomConfig, setIntercomConfig] = useState<any>();

  const [intercomButtonsPrimaryDialNumber, setIntercomButtonsPrimaryDialNumber] = useState<string>();
  const [intercomButtonsPrimaryRepeatFunction, setIntercomButtonsPrimaryRepeatFunction] = useState<any>();
  const [intercomButtonsPrimaryHangupInbound, setIntercomButtonsPrimaryHangupInbound] = useState<any>();
  const [intercomButtonsSecondaryFunction, setIntercomButtonsSecondaryFunction] = useState<any>();
  const [intercomButtonsSecondaryEnable, setIntercomButtonsSecondaryEnable] = useState<any>();
  const [intercomButtonsSecondaryDialNumber, setIntercomButtonsSecondaryDialNumber] = useState<any>();
  const [intercomButtonsSecondaryHangupInbound, setIntercomButtonsSecondaryHangupInbound] = useState<any>();
  const [intercomButtonsSecondaryRepeatFunction, setIntercomButtonsSecondaryRepeatFunction] = useState<any>();
  const [intercomButtonsSecondaryDtmf, setIntercomButtonsSecondaryDtmf] = useState<any>();
  const [intercomLights1function, setIntercomLights1function] = useState<any>();
  const [intercomLights2function, setIntercomLights2function] = useState<any>();
  const [intercomLights1Mediumblink, setIntercomLights1Mediumblink] = useState<any>();
  const [intercomLights2Mediumblink, setIntercomLights2Mediumblink] = useState<any>();

  useEffect(() => {
    setValues(props.model);
  }, [])

  if (!props.model) {
    return (
      <></>
    );
  }

  const setValues = (model: any) => {
    if (model && model.intercom && model.intercom.buttons && model.intercom.lights) {
      setIntercomConfig(model);
      let buttons = model.intercom.buttons;
      let lights = model.intercom.lights;
      setIntercomButtonsPrimaryDialNumber(buttons.primary_dial_number);
      setIntercomButtonsPrimaryHangupInbound(buttons.primary_hangup_inbound);
      setIntercomButtonsPrimaryRepeatFunction({text: RepeatFunction.find((i) => i.value === buttons.primary_repeat_function)?.text, value: buttons.primary_repeat_function});
      setIntercomButtonsSecondaryDialNumber(buttons.secondary_dial_number);
      setIntercomButtonsSecondaryDtmf(buttons.secondary_dtmf_string);
      setIntercomButtonsSecondaryEnable(buttons.secondary_enable);
      setIntercomButtonsSecondaryFunction({text: SecondaryFunction.find((i) => i.value === buttons.secondary_function)?.text, value: buttons.secondary_function});
      setIntercomButtonsSecondaryHangupInbound(buttons.secondary_hangup_inbound);
      setIntercomButtonsSecondaryRepeatFunction({text: RepeatFunction.find((i) => i.value === buttons.secondary_repeat_function)?.text, value: buttons.secondary_repeat_function});

      setIntercomLights1function({text: LightsFunctions.find((i) => i.value === lights['1_function'])?.text, value: lights['1_function']});
      setIntercomLights1Mediumblink({text: LightsFunctions.find((i) => i.value === lights['1_mediumblink'])?.text, value: lights['1_mediumblink']});
      setIntercomLights2function({text: LightsFunctions.find((i) => i.value === lights['2_function'])?.text, value: lights['2_function']});
      setIntercomLights2Mediumblink({text: LightsFunctions.find((i) => i.value === lights['2_mediumblink'])?.text, value: lights['2_mediumblink']});
    }
  }

  const save = () => {
    let updateModel: any = {
      intercom: {
        buttons: {
          primary_dial_number: intercomButtonsPrimaryDialNumber,
          primary_hangup_inbound: intercomButtonsPrimaryHangupInbound,
          primary_repeat_function: intercomButtonsPrimaryRepeatFunction,
          secondary_dial_number: intercomButtonsSecondaryDialNumber,
          secondary_dtmf_string: intercomButtonsSecondaryDtmf,
          secondary_enable: intercomButtonsSecondaryEnable,
          secondary_function: intercomButtonsSecondaryFunction,
          secondary_hangup_inbound: intercomButtonsSecondaryHangupInbound,
          secondary_repeat_function: intercomButtonsSecondaryRepeatFunction,
        },
        lights: {},
      },
    };
    updateModel.intercom.lights['1_function'] = intercomLights1function;
    updateModel.intercom.lights['1_mediumblink'] = intercomLights1Mediumblink;
    updateModel.intercom.lights['2_function'] = intercomLights2function;
    updateModel.intercom.lights['2_mediumblink'] = intercomLights2Mediumblink;

    let mergedIntercomConfig = Object.assign(intercomConfig, updateModel);
    let updateConfigModel = {Config: mergedIntercomConfig, IsPartial: false}
    props.saveEvent(updateConfigModel);
  };

  const cancel = () => {
    setValues(props.model);
    props.cancelEvent();
  };

  return (
    <div className={styles.pageWrapperIntercomConfigContent} id={'IntercomConfigContentExpander'}>
      <div className={styles.panelRowSpaceBetween} style={{ margin: 10 }}>
        <div className={styles.pageTitle}>Buttons and Status Lights</div>
      </div>
      <ExpansionPanel
        title={'Primary Button'}
        expanded={expanded.firstPanel}
        tabIndex={0}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, firstPanel: !expanded.firstPanel });
        }}
      >
        <div>
          {expanded.firstPanel}
          {expanded.firstPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Phone Number</div>
                      <div className='k-form-field-wrap'>
                        <Input
                          maxLength={20}
                          type={'text'}
                          value={intercomButtonsPrimaryDialNumber}
                          onChange={(ev) => {
                            setIntercomButtonsPrimaryDialNumber(ev.value)}}
                        />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Outbound Ring Limit</div>
                      <DropDownList
                        data={RepeatFunction}
                        textField='text'
                        dataItemKey='value'
                        value={intercomButtonsPrimaryRepeatFunction}
                        onChange={(ev) => {
                          setIntercomButtonsPrimaryRepeatFunction(ev.value);
                        }}
                        style={{ width: '300px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Inbound Calls</div>
                      <Checkbox label={'Pressing the button can also hang up inbound calls'} value={intercomButtonsPrimaryHangupInbound}
                                onChange={(ev) => setIntercomButtonsPrimaryHangupInbound(ev.value)} />
                    </div>
                  </div>
                </>
                  :
                <>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Phone Number'} value={intercomButtonsPrimaryDialNumber}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Outbound Ring Limit'} value={intercomButtonsPrimaryRepeatFunction?.text}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Inbound Calls'} checkboxView={true} value={intercomButtonsPrimaryHangupInbound}></DefaultViewControl>
                    </div>
                  </div>
                </>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Second Button'}
        expanded={expanded.secondPanel}
        tabIndex={1}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, secondPanel: !expanded.secondPanel });
        }}
      >
        <div>
          {expanded.secondPanel}
          {expanded.secondPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Installed</div>
                      <Checkbox label={'Active'} value={intercomButtonsSecondaryEnable}
                                onChange={(ev) => setIntercomButtonsSecondaryEnable(ev.value)} />
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Outbound Ring Limit</div>
                      <DropDownList
                        data={SecondaryFunction}
                        textField='text'
                        dataItemKey='value'
                        value={intercomButtonsSecondaryFunction}
                        onChange={(ev) => {
                          setIntercomButtonsSecondaryFunction(ev.value);
                        }}
                        style={{ width: '300px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Phone Number</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={intercomButtonsSecondaryDialNumber}
                               onChange={(ev) => {
                                 setIntercomButtonsSecondaryDialNumber(ev.target.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Outbound Ring Limit</div>
                      <DropDownList
                        data={RepeatFunction}
                        textField='text'
                        dataItemKey='value'
                        value={intercomButtonsSecondaryRepeatFunction}
                        onChange={(ev) => {
                          setIntercomButtonsSecondaryRepeatFunction(ev.value);
                        }}
                        style={{ width: '300px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Inbound Call</div>
                      <Checkbox label={'Pressing the button can also hang up inbound calls'} value={intercomButtonsSecondaryHangupInbound}
                                onChange={(ev) => setIntercomButtonsSecondaryHangupInbound(ev.value)} />
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>DTMF Key Sequence</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={intercomButtonsSecondaryDtmf}
                               onChange={(ev) => {
                                 setIntercomButtonsSecondaryDtmf(ev.target.value);
                               }} />
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Installed</div>
                      <DefaultViewControl checkboxView={true} label={'Active'} value={intercomButtonsSecondaryEnable}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Function'} value={intercomButtonsSecondaryFunction?.text}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Phone Number'} value={intercomButtonsSecondaryDialNumber}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Repeat Function'}
                                          value={intercomButtonsSecondaryRepeatFunction?.text}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Inbound Calls'} checkboxView={true} value={intercomButtonsSecondaryHangupInbound}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'DTMF Key Sequence'} value={intercomButtonsSecondaryDtmf?.text}></DefaultViewControl>
                    </div>
                  </div>
                </>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Status Lights'}
        expanded={expanded.thirdPanel}
        tabIndex={2}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, thirdPanel: !expanded.thirdPanel });
        }}
      >
        <div>
          {expanded.thirdPanel}
          {expanded.thirdPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Status Light 1 Lit</div>
                      <DropDownList
                        data={LightsFunctions}
                        textField='text'
                        dataItemKey='value'
                        value={intercomLights1function}
                        onChange={(ev) => {
                          setIntercomLights1function(ev.value);
                        }}
                        style={{ width: '300px' }}
                      />
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Status Light 1 Blinking</div>
                      <DropDownList
                        data={LightsFunctions}
                        textField='text'
                        dataItemKey='value'
                        value={intercomLights1Mediumblink}
                        onChange={(ev) => {
                          setIntercomLights1Mediumblink(ev.value);
                        }}
                        style={{ width: '300px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Status Light 2 Lit</div>
                      <DropDownList
                        data={LightsFunctions}
                        textField='text'
                        dataItemKey='value'
                        value={intercomLights2function}
                        onChange={(ev) => {
                          setIntercomLights2function(ev.value);
                        }}
                        style={{ width: '300px' }}
                      />
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Status Light 2 Blinking</div>
                      <DropDownList
                        data={LightsFunctions}
                        textField='text'
                        dataItemKey='value'
                        value={intercomLights2Mediumblink}
                        onChange={(ev) => {
                          setIntercomLights2Mediumblink(ev.value);
                        }}
                        style={{ width: '300px' }}
                      />
                    </div>
                  </div>
                </>
                :
                <>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Status Light 1 Lit'} value={intercomLights1function?.text}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Status Light 1 Blinking'} value={intercomLights1Mediumblink?.text}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Status Light 2 Lit'} value={intercomLights2function?.text}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Status Light 2 Blinking'} value={intercomLights2Mediumblink?.text}></DefaultViewControl>
                    </div>
                  </div>
                </>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      {props.editMode ?
        <div className={styles.actionButtonsBox + ' ' + 'k-form-buttons'}>
          <button
            type={'button'}
            className={styles.actionMainButton}
            onClick={() => save()}
          >
            Save
          </button>
          <button
            type={'button'}
            className={styles.actionButtonGray}
            onClick={() => cancel()}
          >
            Cancel
          </button>
        </div> : null
      }

    </div>
  );
};

export default IntercomConfigButtons;
