export interface AppRoute {
  path: string;
  name: string;
  children: AppRoute[];
  defaultData?: any;
  title?: string;
  isChild?: boolean;
  state?: any;
}

export function RoutesList(): AppRoute[] {
  let routerList: AppRoute[] = [];
  const routerConfig: any = RouteConfig();

  Object.keys(routerConfig).forEach((key: string) => {
    let route = routerConfig[key];
    routerList.push(
      {
        path: route.path,
        name: route.name,
        children: route.children,
        defaultData: route.defaultData,
        title: route.title,
      },
    );

    if (route.children.length > 0) {
      route.children.forEach((child: AppRoute) => {
        routerList.push(child);
      })
    }
  });

  return routerList;
}

export function RouteConfig(): any {
  return {
    Home: {
      path: '/',
      name: 'Home',
      children: [],
      defaultData: null,
    },
    Intercoms: {
      path: '/intercoms',
      name: 'Intercoms',
      defaultData: null,
      title: 'Intercoms',
      children: [
        {
          path: '/intercoms/:id',
          state: '',
          name: 'Intercom',
          children: [],
          defaultData: null,
          title: 'Intercom Detail',
          isChild: true,
        }
      ],
    },
    Firmware: {
      path: '/firmware',
      name: 'Firmware',
      children: [
        {
          path: '/firmware/:id',
          state: '',
          name: 'Firmware',
          children: [],
          defaultData: null,
          title: 'Firmware Detail',
          isChild: true,
        }
      ],
      defaultData: null,
      title: 'Firmware'
    },
    AuditLogs: {
      path: '/audit-log',
      name: 'Audit Log',
      children: [],
      defaultData: null,
      title: 'Audit Log'
    }
  };
}
